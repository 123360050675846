import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISimpleUser } from '../../models/Auth.model'
import { getAllUsers } from '../thunk/userController'
import { changePassword } from '../thunk/changePassword'
import { RootState } from '../configureStore'

interface IUserControllerState {
  loading: boolean
  error: string
  users?: ISimpleUser[]
}

export const initialState: IUserControllerState = {
  loading: false,
  error: undefined,
  users: undefined
}

export const userControllerSlice = createSlice({
  name: 'userControl',
  initialState,
  reducers: {    
    setUsers: (state, action: PayloadAction<ISimpleUser[]>) => {
      state.users = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllUsers.pending, state => {
        state.loading = true
      })
      .addCase(
        getAllUsers.fulfilled,
        (state, action: PayloadAction<ISimpleUser[]>) => {
          state.loading = false
          state.error = undefined
          state.users = action.payload
        }
      )
      .addCase(getAllUsers.rejected, (state, action) => {
        state.error = action.payload?.data
        state.loading = false
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(changePassword.pending, (state, action) => {
        state.loading = true
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false
        // state.error = action.error
      })
  }
})

export const selectUserControllerState = (
  state: RootState
): IUserControllerState => state.user

export const selectUsers = (state: RootState): ISimpleUser[] => state.user.users

export const selectLoading = (state: RootState): Boolean => state.user.loading


export default userControllerSlice.reducer
export const { setUsers } = userControllerSlice.actions
