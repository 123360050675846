import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { isAxiosError } from 'utils/errors'
import axiosInstance from '../../app/axios'
import { RES_CONTROLLER } from '../../constants/URLS'
import { IReservationsError } from '../../models/Reservation.model'

export interface IDateParams {
  fromDate: string
  toDate: string
}

export const countSeats = createAsyncThunk<
  any,
  IDateParams,
  {
    rejectValue: AxiosResponse<IReservationsError>
  }
>(
  'reservation/countSeats',
  async (params: IDateParams, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(RES_CONTROLLER.countSeats, {
        params
      })

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        return rejectWithValue(error.response)
      }
    }
  }
)
