import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { AppDispatch, RootState } from 'store/configureStore'
import { hide, show } from 'store/reducers/LoaderReducer'
import { isAxiosError } from 'utils/errors'
import { getUrlParams } from 'utils/urlrelated'
import axiosInstance from '../../app/axios'
import { RES_CONTROLLER } from '../../constants/URLS'
import {
  IMyReservationParams,
  IMyReservationsResponse,
  IReservationContent,
  IReservationContentWithDate,
  IReservationsError
} from '../../models/Reservation.model'
export interface IGroupReservationsParams extends IMyReservationParams {
  username?: string
  status?: Array<string>
  pageSize?: number
}

export const getGroupReservationsbyDay = createAsyncThunk<
  IMyReservationsResponse<IReservationContentWithDate>,
  IGroupReservationsParams | undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
    state: RootState
    dispatch: AppDispatch
  }
>(
  'reservation/groupReservationsByDay',
  async (
    params: IGroupReservationsParams,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      let path = getUrlParams(params)

      dispatch(show())

      const { data } = await axiosInstance.get<
        IMyReservationsResponse<IReservationContent>
      >(RES_CONTROLLER.byRole + path)

      dispatch(hide())

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
