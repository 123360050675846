import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { isAxiosError } from 'utils/errors'
import axiosInstance from '../../app/axios'
import { USER_CONTROLLER } from '../../constants/URLS'
import { ISimpleUser } from '../../models/Auth.model'

export const getUserDetails = createAsyncThunk<
  ISimpleUser,
  undefined,
  {
    rejectValue: AxiosResponse
  }
>('userControl/myInfo', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get<ISimpleUser>(
      USER_CONTROLLER.getUserDetails
    )
    return data
  } catch (err) {
    if (isAxiosError(err)) {
      const error = err as AxiosError
      return rejectWithValue(error.response)
    } else {
      // generic & not related to network error
      console.error(err)
    }
  }
})
