import { createAsyncThunk } from '@reduxjs/toolkit'
import { IMode } from 'app/components/Booking/Booking'
import { AxiosError, AxiosResponse } from 'axios'
import { isAxiosError } from 'utils/errors'
import { getUrlParams } from 'utils/urlrelated'
import axiosInstance from '../../app/axios'
import { RES_CONTROLLER } from '../../constants/URLS'
import { IReservationsError, ISeat } from '../../models/Reservation.model'

export interface IFindAvailableSeatsParams {
  dateStart: string
  dateEnd?: string
  recurringReservation: boolean
  timeReservation: number | 539
  recurrencePatternType: IMode
  recurrenceTimeZone: string
  seriesName: 'daily_numbered' | 'weekly_numbered' | 'monthly_numbered'
  recurrenceRangeType: 'NUMBERED'
  numberOfOccurrence: number
  daysOfWeek?: string[]
  dayOfMonth?: number
}

export const findAvailableSeats = createAsyncThunk<
  ISeat[],
  IFindAvailableSeatsParams | undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
  }
>('reservation/findAvailableSeats', async (params, { rejectWithValue }) => {
  try {
    let path = getUrlParams(params)
    const { data } = await axiosInstance.get<ISeat[]>(
      RES_CONTROLLER.findSeats + path
    )

    return data
  } catch (err) {
    if (isAxiosError(err)) {
      const error = err as AxiosError<IReservationsError>
      return rejectWithValue(error.response)
    } else {
      // generic & not related to network error
      console.error(err)
    }
  }
})
