import * as React from 'react'
import { useEffect } from 'react'
import Routes from './routes'
const r = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

const App = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    Object.assign(root?.style, r)
  }, [])

  return <Routes />
}

export default App
