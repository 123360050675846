import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { showToast } from '../reducers/ToastReducer'
import axiosInstance from '../../app/axios'
import { IReservationsError } from '../../models/Reservation.model'
import { isAxiosError } from '../../utils/errors'
import { USER_CONTROLLER } from './../../constants/URLS'

type IchangePassword = {
  token: string
  newPassword: string
}
export const changePasswordFromRecovery = createAsyncThunk<
  void,
  IchangePassword,
  {
    rejectValue: AxiosResponse<IReservationsError>
  }
>(
  'auth/createNewPassword',
  async (requestBody: IchangePassword, { rejectWithValue, dispatch }) => {
    try {
      const authData = ['userJwt', 'auth']
      authData.forEach((name: string) => localStorage.removeItem(name))
      const { data } = await axiosInstance.post(
        `${USER_CONTROLLER.changePassword}`,
        {
          newPassword: requestBody.newPassword
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${requestBody.token}`,
            'X-No-Redirect': true // this header prevents for Axios catching 401 status & redirect to signInPage
          }
        }
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Nuova password creata con successo!'
        })
      )
      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
       dispatch(
          showToast({
            type: 'error',
            message:
              error.response?.data?.details?.[0] ||
              error.response?.data?.message ||
              'Errore durante la creazione della nuova password'
          })
        )
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
