import Box from '@material-ui/core/Box'
import React from 'react'
import { Copyright } from '../components'
import { MainCard } from '../components/common'
import {
  BookIcon,
  Description,
  IconContainer,
  Logo,
  StyledContainer,
  WhereToVoteIcon
} from '../pages/LoginPage/LoginPage.styled'

const AuthLayout: React.FC<{ children: JSX.Element }> = ({ children }) => {
  return (
    <StyledContainer>
      <MainCard>
        <Logo src="anokilogo.png" />
        <IconContainer>
          <WhereToVoteIcon />
          <Description>Prenota una postazione</Description>
          <BookIcon />
        </IconContainer>
        {children}
      </MainCard>
      <Box mt={2}>
        <Copyright />
      </Box>
    </StyledContainer>
  )
}

export default AuthLayout
