import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { isAxiosError } from '../../utils/errors'
import axiosInstance from '../../app/axios'
import { SEAT_CONTROLLER } from '../../constants/URLS'
import { IReservationsError } from '../../models/Reservation.model'

export const countTotalSeats = createAsyncThunk<
  any,
  undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
  }
>(
  'ReservationsDay/countTotalSeats',

  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get<number>(
        SEAT_CONTROLLER.countSeat
      )
      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        return rejectWithValue(error.response)
      }
    }
  }
)
