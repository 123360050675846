import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Copyright } from 'app/components'
import * as React from 'react'
import { useState, VFC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowBackIos } from '@material-ui/icons'
import { Anchor, StyledContainer } from '../LoginPage/LoginPage.styled'
import styled from 'styled-components'
import { MainCard, StyledTextField } from 'app/components/common'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import ShadowButton from 'app/components/ShadowButton'
import { recoverPasswordRequest } from 'store/thunk/recoverPassword'
import { useAppDispatch } from 'store/configureStore'

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: normal;
  border-bottom: 6px solid ${props => props.theme.palette.secondary.main};
  text-align: center;
  display: block;
  margin: 0 auto 15px;
  transition: 0.2s;
  transition-timing-function: ease-in-out;

  :hover {
    transform: translateY(-5px);
  }
`
const StyledMainCard = styled(MainCard).attrs(() => ({
  fade: true
}))`
  position: relative;
  padding: 15px 31px;

  .MuiFormHelperText-contained {
    position: absolute;
    top: 7px;
    right: 0;
  }
`
const BackIcon = styled(ArrowBackIos)`
  cursor: pointer;
  position: absolute;
  height: 45px;
  width: 45px;
  left: 12px;
  top: 12px;
  color: ${props => props.theme.palette.secondary.main};
`

const SubmitButton = styled(ShadowButton).attrs(({ theme, disabled }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main,
  primary: true,
  size: 'large',
  disabled
}))`
  margin-bottom: 0;
  width: 100%;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  box-shadow: none;

  :hover {
    box-shadow: none;
  }
`

const ResetPasswordInfo = styled.div`
  font-size: 16px;
  margin-block-start: 2.5rem;
`

const validationSchema = Yup.object({
  email: Yup.string().email('Email non valida').required('Email richiesta')
})
const initialValues = {
  email: ''
}
const RecoverPassword: VFC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [recoverPasswordSuccess, setRecoverPasswordSuccess] = useState(false)

  const goToSignIn = React.useCallback(() => {
    navigate('/sign-in', { replace: true })
  }, [navigate])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (data, { resetForm, setSubmitting }) => {
      try {
        setSubmitting(true)
        const resultAction = await dispatch(
          recoverPasswordRequest({ usernameOrEmail: data.email })
        )
        if (recoverPasswordRequest.fulfilled.match(resultAction)) {
          setRecoverPasswordSuccess(true)
        }
      } finally {
        setSubmitting(false)
        resetForm()
      }
    }
  })
  const onKeyDown = (submit, isValid, event) => {
    if (isValid && event.key === 'Enter') {
      submit()
    }
  }
  const handleNewPasswordRecovery = () => setRecoverPasswordSuccess(false)

  return (
    <StyledContainer>
      <StyledMainCard>
        <BackIcon onClick={goToSignIn} />
        {!recoverPasswordSuccess && (
          <>
            <Title>Reset Password</Title>
            <Typography align="center" variant="body2" color="textSecondary">
              Inserisci l'email associata al tuo account e ti invieremo un'email
              con le istruzioni per resettare la password
            </Typography>
            <form
              noValidate
              onKeyDown={event =>
                onKeyDown(formik.handleSubmit, formik.isValid, event)
              }
            >
              <StyledTextField
                size="small"
                id="email"
                label="EMAIL"
                type="email"
                autoComplete="email"
                variant="outlined"
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
                fullWidth
                {...formik.getFieldProps('email')}
              />
              <SubmitButton
                onClick={formik.handleSubmit}
                buttonText={formik.isSubmitting ? 'caricamento...' : 'continua'}
                disabled={
                  !formik.isValid || !formik.dirty || formik.isSubmitting
                }
              />
            </form>
          </>
        )}
        {recoverPasswordSuccess && (
          <>
            <Title>Controlla la tua casella di posta</Title>
            <Typography align="center" variant="body2" color="textSecondary">
              Ti abbiamo inviato una mail contenente le istruzioni per resettare
              la password
            </Typography>
            <ResetPasswordInfo>
              Non hai ricevuto l'email? controlla tra la spam oppure{' '}
              <Anchor onClick={handleNewPasswordRecovery}>
                riprova con un'altra email
              </Anchor>
            </ResetPasswordInfo>
          </>
        )}
      </StyledMainCard>
      <Box mt={2}>
        <Copyright />
      </Box>
    </StyledContainer>
  )
}

export default RecoverPassword
