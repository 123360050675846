import { Avatar, ButtonBase, Theme } from '@material-ui/core'
// assets
import MenuIcon from '@material-ui/icons/Menu'
// material-ui
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import NewReservation from './NewReservation'
import ProfileSection from './ProfileSection'
// style constant
const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1
  },
  headerAvatar: {
    cursor: 'pointer',
    borderRadius: '8px',
    width: '34px',
    height: '34px',
    fontSize: '1.2rem',
    transition: 'all .2s ease-in-out',
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: 'white'
    }
  },
  boxContainer: {
    width: '228px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }
}))

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header: React.VFC<{ handleLeftDrawerToggle: () => void }> = ({
  handleLeftDrawerToggle
}) => {
  const classes = useStyles()
  return (
    <>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <ButtonBase>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <MenuIcon fontSize="default" />
          </Avatar>
        </ButtonBase>
      </div>

      <div className={classes.grow} />
      <div className={classes.grow} />

      {/* newReservation & profile */}
      <NewReservation />
      <ProfileSection />
    </>
  )
}

export default Header
