import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Group, ISimpleUser } from '../../models/Auth.model'
import { getAllUsers } from '../thunk/userController'
import { changePassword } from '../thunk/changePassword'
import { RootState } from '../configureStore'
import { getAllGroups } from '../thunk/groupController'

interface GroupControllerState {
  loading: boolean
  error?: string
  groups?: Group[]
}

export const initialState: GroupControllerState = {
  loading: false,
  error: undefined,
  groups: undefined
}

export const groupControllerSlice = createSlice({
  name: 'groupControl',
  initialState,
  reducers: {    
    setGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllGroups.pending, state => {
        state.loading = true
      })
      .addCase(
        getAllGroups.fulfilled,
        (state, action: PayloadAction<Group[]>) => {
          state.loading = false
          state.error = undefined
          state.groups = action.payload
        }
      )
      .addCase(getAllGroups.rejected, (state, action) => {
        state.error = action.payload?.data
        state.loading = false
      })
  }
})

export const groupControllerState = (
  state: RootState
): GroupControllerState => state.group

export const selectGroups = (state: RootState): Group[] => state.group.groups

export const selectLoading = (state: RootState): Boolean => state.group.loading


export default groupControllerSlice.reducer
export const { setGroups } = groupControllerSlice.actions
