import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { USER_CONTROLLER } from 'constants/URLS'
import { AuthError, ResetPasswordRequest } from 'models/Auth.model'
import { isAxiosError } from 'utils/errors'
import axiosInstance from '../../app/axios'
import { hide, show } from '../reducers/LoaderReducer'
import { showToast } from '../reducers/ToastReducer'
import { IReservationsError } from 'models/Reservation.model'

export const recoverPasswordRequest = createAsyncThunk<
  null,
  ResetPasswordRequest,
  {
    rejectValue: AxiosResponse<AuthError>
  }
>(
  'auth/recover-password',
  async (requestBody, { rejectWithValue, dispatch }) => {
    try {
      dispatch(show())
      const requestRecoverPasswordUrl = new URL(USER_CONTROLLER.recoverPassword)
      requestRecoverPasswordUrl.searchParams.set(
        'usernameOrEmail',
        requestBody.usernameOrEmail
      )
      const request = await axiosInstance.post(requestRecoverPasswordUrl.href)

      if (request.status === 200) {
        dispatch(
          showToast({
            type: 'success',
            message: 'richiesta reset password effettuata con successo!'
          })
        )
      } else {
        dispatch(
          showToast({
            type: 'error',
            message: 'Errore durante la richiesta di reset password'
          })
        )
      }

      dispatch(hide())

      return request.data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        dispatch(hide())

        dispatch(
          showToast({
            type: 'error',
            // message: err.response?.data?.message || 'Nome utente o password errati'
            message:
              error.response?.data?.details?.[0] ||
              error.response?.data?.message ||
              'Errore durante la richiesta di reset password'
          })
        )

        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
