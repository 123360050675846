import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { ArrowBackIos } from '@material-ui/icons'
import { useFormik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//import { SIGN_IN_PATH } from '../../routes'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import {
  resetRegistration,
  selectRegistration
} from '../../../store/reducers/AuthReducer'
import { doRegistration } from '../../../store/thunk/registration'
import { Copyright } from '../../components'
import { MainCard, StyledTextField } from '../../components/common'
import ShadowButton from '../../components/ShadowButton'

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: normal;
  border-bottom: 6px solid ${props => props.theme.palette.secondary.main};
  text-align: center;
  display: block;
  margin: 0 auto 15px;
  transition: 0.2s;
  transition-timing-function: ease-in-out;

  :hover {
    transform: translateY(-5px);
  }
`

const StyledContainer = styled(Container)`
  padding: 0 20px;
  max-width: 600px;
`

const StyledMainCard = styled(MainCard).attrs(() => ({
  fade: true
}))`
  position: relative;
  padding: 15px 31px;

  .MuiFormHelperText-contained {
    position: absolute;
    top: 7px;
    right: 0;
  }
`

const RegistrationButton = styled(ShadowButton).attrs(
  ({ theme, disabled }) => ({
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    primary: true,
    size: 'large',
    disabled
  })
)`
  margin-bottom: 0;
  width: 100%;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  box-shadow: none;

  :hover {
    box-shadow: none;
  }
`

const BackIcon = styled(ArrowBackIos)`
  cursor: pointer;
  position: absolute;
  height: 45px;
  width: 45px;
  left: 12px;
  top: 12px;
  color: ${props => props.theme.palette.secondary.main};
`

const initialValues = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: ''
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('Nome richiesto'),
  lastName: Yup.string().required('Cognome richiesto'),
  email: Yup.string().email('Email non valida').required('Email richiesta'),
  username: Yup.string().required('Username richiesto'),
  confirmEmail: Yup.string()
    .email('Email non valida')
    .oneOf([Yup.ref('email'), null], "L'email deve corrispondere")
    .required('Conferma email richiesta'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$%!?@])(?=.{8,})/,
      'Password non valida'
    )
    .required('Password richiesta'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'La password deve corrispondere')
    .required('Conferma password richiesta')
})

const Registration: React.VFC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const registration = useSelector(selectRegistration)

  const goToSignIn = useCallback(() => {
    navigate('/sign-in', { replace: true })
  }, [navigate])

  useEffect(() => {
    dispatch(resetRegistration())
  }, [dispatch])

  useEffect(() => {
    if (registration) {
      dispatch(resetRegistration())
      goToSignIn()
    }
  }, [dispatch, goToSignIn, registration])

  const onKeyDown = (submit, isValid, event) => {
    if (isValid && event.key === 'Enter') {
      submit()
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: user => {
      dispatch(
        doRegistration({
          email: user.email,
          firstname: user.firstName,
          lastname: user.lastName,
          username: user.username,
          password: user.password
        })
      )
    }
  })

  return (
    <StyledContainer>
      <StyledMainCard>
        <BackIcon onClick={goToSignIn} />
        <Title>Registrazione</Title>
        <form
          onKeyDown={event =>
            onKeyDown(formik.handleSubmit, formik.isValid, event)
          }
        >
          <StyledTextField
            size="small"
            id="firstName"
            label="NOME"
            autoComplete="firstName"
            variant="outlined"
            error={formik.touched.firstName && !!formik.errors.firstName}
            helperText={formik.touched.firstName && formik.errors.firstName}
            margin="normal"
            fullWidth
            {...formik.getFieldProps('firstName')}
          />
          <StyledTextField
            size="small"
            id="lastName"
            autoComplete="lastName"
            label="COGNOME"
            variant="outlined"
            error={formik.touched.lastName && !!formik.errors.lastName}
            helperText={formik.touched.lastName && formik.errors.lastName}
            margin="normal"
            fullWidth
            {...formik.getFieldProps('lastName')}
          />
          <StyledTextField
            size="small"
            id="username"
            autoComplete="username"
            label="USERNAME"
            variant="outlined"
            error={formik.touched.username && !!formik.errors.username}
            helperText={formik.touched.username && formik.errors.username}
            margin="normal"
            fullWidth
            {...formik.getFieldProps('username')}
          />
          <StyledTextField
            size="small"
            id="email"
            label="EMAIL"
            autoComplete="email"
            variant="outlined"
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
            fullWidth
            {...formik.getFieldProps('email')}
          />
          <StyledTextField
            size="small"
            id="confirmEmail"
            label="CONFERMA EMAIL"
            autoComplete="confirmEmail"
            variant="outlined"
            error={formik.touched.confirmEmail && !!formik.errors.confirmEmail}
            helperText={
              formik.touched.confirmEmail && formik.errors.confirmEmail
            }
            margin="normal"
            fullWidth
            {...formik.getFieldProps('confirmEmail')}
          />
          <StyledTextField
            size="small"
            id="password"
            label="PASSWORD"
            autoComplete="password"
            variant="outlined"
            type="password"
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
            margin="normal"
            fullWidth
            {...formik.getFieldProps('password')}
          />
          <StyledTextField
            size="small"
            id="confirmPassword"
            label="CONFERMA PASSWORD"
            autoComplete="confirmPassword"
            variant="outlined"
            type="password"
            error={
              formik.touched.confirmPassword && !!formik.errors.confirmPassword
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            margin="normal"
            fullWidth
            {...formik.getFieldProps('confirmPassword')}
          />
          <RegistrationButton
            onClick={formik.handleSubmit}
            buttonText="REGISTRATI"
            disabled={!formik.isValid || !formik.dirty}
          >
            Attiva la prova gratuita
          </RegistrationButton>
        </form>
      </StyledMainCard>
      <Box mt={2}>
        <Copyright />
      </Box>
    </StyledContainer>
  )
}

export default Registration
