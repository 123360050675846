export const AUTH_CONTROLLER = {
  signIn: `${process.env.REACT_APP_BASE_URL}/signin`,
  signUp: `${process.env.REACT_APP_BASE_URL}/signup`
}

export const RES_CONTROLLER = {
  approveReservation: `${process.env.REACT_APP_BASE_URL}/reservations/approve`,
  myReservations: `${process.env.REACT_APP_BASE_URL}/reservations/my-reservations`,
  toApproveReservations: `${process.env.REACT_APP_BASE_URL}/reservations/to-approve`,
  updateStatus: `${process.env.REACT_APP_BASE_URL}/reservations/updateStatus`,
  updateReservation: `${process.env.REACT_APP_BASE_URL}/reservations/update-reservation`,
  countSeats: `${process.env.REACT_APP_BASE_URL}/reservations/count-available-seats-on-dates`,
  createGeneric: `${process.env.REACT_APP_BASE_URL}/reservations/generic`,
  createSpecific: `${process.env.REACT_APP_BASE_URL}/reservations/specific`,
  byRole: `${process.env.REACT_APP_BASE_URL}/reservations/find-reservation-by-role`,
  findSeats: `${process.env.REACT_APP_BASE_URL}/reservations/find-available-seats`,
  findReservation: `${process.env.REACT_APP_BASE_URL}/reservations/find-reservation`,
  recursiveReservation: `${process.env.REACT_APP_BASE_URL}/reservations/recursive-reservation`,
  groupReservation: `${process.env.REACT_APP_BASE_URL}/reservations/specific-reservation-group`,
  recursiveGroupReservation: `${process.env.REACT_APP_BASE_URL}/reservations/recursive-reservation-group`,
  reservationDetail: `${process.env.REACT_APP_BASE_URL}/reservations/find-reservation`
}

export const USER_CONTROLLER = {
  getUsers: `${process.env.REACT_APP_BASE_URL}/users/all`,
  createUser: `${process.env.REACT_APP_BASE_URL}/users/addUser`,
  deleteUser: `${process.env.REACT_APP_BASE_URL}/users/delete`,
  updateUser: `${process.env.REACT_APP_BASE_URL}/users/update`,
  getUserDetails: `${process.env.REACT_APP_BASE_URL}/users/get-user-details`,
  changePassword: `${process.env.REACT_APP_BASE_URL}/users/change-password`,
  recoverPassword: `${process.env.REACT_APP_BASE_URL}/users/reset-password`
}

export const GROUP_CONTROLLER = {
  getGroups: `${process.env.REACT_APP_BASE_URL}/groups/findAllEnabled`,
  createGroup: `${process.env.REACT_APP_BASE_URL}/groups/create-group`,
  updateGroup: `${process.env.REACT_APP_BASE_URL}/groups/update-group`,
  deleteGroup: `${process.env.REACT_APP_BASE_URL}/groups/delete-group`,
}

export const AUTHORITY_CONTROLLER = {
  getAuthorities: `${process.env.REACT_APP_BASE_URL}/authorities`,
}

export const SEAT_CONTROLLER = {
  countSeat: `${process.env.REACT_APP_BASE_URL}/seat/total-number`
}
