import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { showToast } from 'store/reducers/ToastReducer'
import axiosInstance from '../../app/axios'
import {
  IReservationContent,
  IReservationsError
} from '../../models/Reservation.model'
import { isAxiosError } from '../../utils/errors'
import { RES_CONTROLLER } from './../../constants/URLS'

export const updateReservation = createAsyncThunk<
  IReservationContent,
  IReservationContent | undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
  }
>(
  'reservation/updateReservation',
  async (requestBody: IReservationContent, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.put(
        `${RES_CONTROLLER.updateReservation}`,
        {
          ...requestBody
        }
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Prenotazione aggiornata con successo!'
        })
      )
      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message:
              error.response?.data?.details?.[0] ||
              error.response?.data?.message ||
              "Errore durante l'aggiornamento della prenotazione"
          })
        )
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
