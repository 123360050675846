export function authorityToName (authority: String) {
    switch (authority) {
        case 'ROLE_USER':
            return 'User';
        case 'ROLE_ADMIN':
            return 'Admin';
        case 'ROLE_GROUP_ADMIN':
            return 'Group Admin';
        case 'ROLE_USER_SUPERVISOR':
            return 'User Supervisor';
        case 'ROLE_GROUP_SUPERVISOR':
            return 'Group Supervisor';
        case 'ROLE_RESET_PASSWORD':
            return 'Reset Password';
        default:
            return authority;
    }
}
