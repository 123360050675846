import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { openModal } from 'store/reducers/ModalReducer'
import { isAxiosError } from 'utils/errors'
import axiosInstance from '../../app/axios'
import { RES_CONTROLLER } from '../../constants/URLS'
import {
  IGenericGroupReservationParams,
  IReservationsError,
  IThunkWithModal
} from '../../models/Reservation.model'
import { AppDispatch } from '../configureStore'
import { hide, show } from '../reducers/LoaderReducer'
import { showToast } from '../reducers/ToastReducer'

export const createGroupRecursiveReservation = createAsyncThunk<
  any,
  IThunkWithModal<IGenericGroupReservationParams> | undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
    dispatch: AppDispatch
  }
>(
  'reservation/createGroupRecursiveReservation',
  async ({ params, modal }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(show())

      const { data } = await axiosInstance.post<any>(
        RES_CONTROLLER.recursiveGroupReservation,
        {
          ...params
        }
      )

      dispatch(hide())

      if (modal) {
        dispatch(openModal(modal))
      }

      dispatch(
        showToast({
          type: 'success',
          message: 'Prenotazione ricorsiva di gruppo creata con successo'
        })
      )

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message:
              error.response?.data?.details?.[0] ||
              error.response?.data?.message ||
              'Errore durante la creazione della prenotazione'
          })
        )

        dispatch(hide())

        return rejectWithValue(error.response)
      }
    }
  }
)
