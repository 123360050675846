import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthState, ISimpleUser, IUser } from 'models/Auth.model'
import { doLogin } from 'store/thunk/login'
import { getUserDetails } from 'store/thunk/getUserDetails'
import { RootState } from '../configureStore'
import { doRegistration } from '../thunk/registration'

export const initialState: AuthState = {
  logged: false,
  firstLogin: false,
  loading: false,
  user: undefined,
  details: undefined,
  registered: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    doFirstLogin(state) {
      state.firstLogin = false
    },
    logout(state) {
      state.logged = false
      state.user = undefined
      state.details = undefined
      state.firstLogin = false
    },
    resetRegistration(state) {
      state.registered = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(doLogin.pending, state => {
        state.loading = true
      })
      .addCase(doLogin.fulfilled, (state, action: PayloadAction<IUser>) => {
        state.user = action.payload
        state.loading = false
        state.logged = true
        state.firstLogin = true
        //state.myActions = getMyActions(action.payload.authority_names)
      })
      .addCase(doLogin.rejected, state => {
        state.loading = false
      })
      .addCase(doRegistration.pending, state => {
        state.loading = true
      })
      .addCase(doRegistration.fulfilled, state => {
        state.loading = false
        state.registered = true
      })
      .addCase(doRegistration.rejected, state => {
        state.loading = false
      })
      .addCase(getUserDetails.pending, state => {
        state.loading = true
      })
      .addCase(
        getUserDetails.fulfilled,
        (state, action: PayloadAction<ISimpleUser>) => {
          state.loading = false
          state.details = action.payload
        }
      )
      .addCase(getUserDetails.rejected, state => {
        state.loading = false
      })
  }
})

export const selectAuthState = (state: RootState): AuthState => state.auth

export const selectUser = (state: RootState): IUser => state.auth.user

export const selectLoggedIn = (state: RootState): boolean => state.auth.logged

export const selectFirstLogin = (state: RootState): boolean =>
  state.auth.firstLogin

export const selectDetails = (state: RootState): ISimpleUser =>
  state.auth.details

export const selectRegistration = (state: RootState): boolean =>
  state.auth.registered

export const { doFirstLogin, logout, resetRegistration } = authSlice.actions

export default authSlice.reducer
