/**
 *
 * Copyright
 *
 */
import { Link, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { messages } from './messages'
import styled from 'styled-components/macro'

const StyledTypography = styled(Typography)`
  margin-bottom: 15px;
`

export function Copyright() {
  const { t } = useTranslation()

  return (
    <StyledTypography variant="body2" color="textSecondary" align="center">
      {t(...messages.copyright())}
      <Link color="inherit" href="https://www.anoki.it/">
        {t(...messages.company())}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </StyledTypography>
  )
}
