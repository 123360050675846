import React, { useState, useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import { MenuItem, Fade, TableRow } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import HourglassFullTwoToneIcon from '@material-ui/icons/HourglassFullTwoTone'
import CancelIcon from '@material-ui/icons/Cancel'
import Tooltip from '@material-ui/core/Tooltip'
import { Menu, IconButton } from '@material-ui/core'
import { ACTIONS } from '../../../utils/actionClick'
import { useDispatch, useSelector } from 'react-redux'
import { IReservationContent } from '../../../models/Reservation.model'
import { selectReservations } from '../../../store/reducers/ReservationsReducer'
import { selectFormModalState } from '../../../store/reducers/ConfirmSeatModalReducer'

interface rowDetail {
  rowReserv: IReservationContent
  handleStatusReserv: () => void
}
const TableRowReserv: React.FC<rowDetail> = ({
  rowReserv,
  handleStatusReserv
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { loading } = useSelector(selectReservations)
  const [wasLoading, setWasLoading] = useState(false)
  const { open } = useSelector(selectFormModalState)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const ITEM_HEIGHT = 48
  const dispatch = useDispatch()

  const useStyles = makeStyles((theme: Theme) => ({
    tablecell: {
      fontWeight: 500,
      fontSize: '1.2rem',
      textAlign: 'center',
      width: '300px'
    },

    avatar: {
      background: '#ef7d44'
    },
    actionMenuItem: {
      '&:hover': {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        backgroundColor: 'inherit'
      }
    },

    personaContainer: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.between('sm', 'md')]: {
        justifyContent: 'center',
        padding: '5px'
      }
    },
    personalabel: {
      textAlign: 'start',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      [theme.breakpoints.between('sm', 'md')]: {
        display: 'none'
      }
    },
    salalabel: {
      width: 'auto',
      minWidth: 0,
      maxWidth: '200px',
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    optionStatus: {
      '&:hover': {
        cursor: 'pointer'
      },
      [theme.breakpoints.down('sm')]: {
        transform: 'rotate(90deg)'
      },
      [theme.breakpoints.up('sm')]: {
        transform: 'rotate(0deg)'
      }
    }
  }))
  const classes = useStyles()
  return (
    <TableRow>
      <TableCell className={classes.tablecell}>
        <Tooltip placement="top" title={rowReserv.seat.room.name}>
          <Typography noWrap variant="h6" color="initial">
            {rowReserv.seat.room.name}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.tablecell}>
        <Typography variant="h6" color="initial">
          {rowReserv.seat.description}
        </Typography>
      </TableCell>
      <TableCell className={classes.tablecell}>
        <div className={classes.personaContainer}>
          <Tooltip
            title={`${rowReserv.user.firstname} ${rowReserv.user.lastname} `}
          >
            <Avatar
              className={classes.avatar}
              alt={`${rowReserv.user.firstname} ${rowReserv.user.lastname}`}
            >
              {`${rowReserv.user.firstname[0]}${rowReserv.user.lastname[0]}`}
            </Avatar>
          </Tooltip>

          <Typography
            className={classes.personalabel}
            variant="h6"
            color="initial"
          >
            {`${rowReserv.user.firstname} ${rowReserv.user.lastname}`}
          </Typography>
        </div>
      </TableCell>
      <TableCell className={classes.tablecell} align="right">
        <div>
          {rowReserv.reservationStatus.status === 'ACCEPTED' && (
            <CheckCircleIcon fontSize="large" style={{ color: '#5fb930' }} />
          )}
          {rowReserv.reservationStatus.status === 'REJECTED' && (
            <RemoveCircleIcon fontSize="large" style={{ color: '#fd5151' }} />
          )}
          {rowReserv.reservationStatus.status === 'PENDING' && (
            <HourglassFullTwoToneIcon
              fontSize="large"
              style={{ color: '#93771c' }}
            />
          )}
          {rowReserv.reservationStatus.status === 'CANCELLED' && (
            <CancelIcon fontSize="large" style={{ color: '#7f7f7f' }} />
          )}
          <IconButton onClick={handleClick}>
            <MoreVert className={classes.optionStatus} />
          </IconButton>
        </div>
        <>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            TransitionComponent={Fade}
            onClose={handleClose}
            PaperProps={{
              style: {
                minHeight: ITEM_HEIGHT * 1.5,
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '15ch'
              }
            }}
          >
            {ACTIONS?.map(action => {
              return (
                <MenuItem
                  className={classes.actionMenuItem}
                  key={action.name}
                  onClick={() => {
                    action.action(rowReserv.id, dispatch)
                    handleStatusReserv()
                    handleClose()
                  }}
                >
                  {action.name}
                </MenuItem>
              )
            })}
          </Menu>
        </>
      </TableCell>
    </TableRow>
  )
}

export default TableRowReserv
