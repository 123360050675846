import { createUser, getAllUsers } from '../../../store/thunk/userController'
import { getAuthorities } from '../../../store/thunk/authorithyController'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'
import { selectUsers, setUsers } from 'store/reducers/userControllerReducer'
import { selectAuthorities } from 'store/reducers/AuthorityReducer'
import NoData from '../NoData'
import UsersTable from './table/UsersTable'
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { Add } from '@material-ui/icons'

import CreationDialog from './CreationDialog'


const Users = () => {
    const dispatch = useDispatch()
    const users = useSelector(selectUsers)
    const authorities = useSelector(selectAuthorities)
    const [usersToShow, setUsersToShow] = useState(users);
    const { pageable = { pageNumber: 0 }, totalPages = 0 } = {
      ...users
    }

    const [paginationNumber, setPaginationNumber] = useState(pageable.pageNumber)

    const [showClearIcon, setShowClearIcon] = useState("none");

    const [search, setSearch] = useState("");

    const [openCreate, setOpenCreate] = useState(false);

    const [userCreation, setUserCreation] = useState({
      firstname: "",
      lastname: "",
      email: "",
      username: "",
      enabled: false,
      authorities: []
    })


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setShowClearIcon(event.target.value === "" ? "none" : "flex");
      setSearch(event.target.value);
      filter(event.target.value);
    };

    const filter = (value: string) => {
      //filter user by name
      var arrayForFilter = [...users]
      arrayForFilter = arrayForFilter.filter(user => user.firstname.toLowerCase().includes(value.toLowerCase()) || user.lastname.toLowerCase().includes(value.toLowerCase()) || user.username.toLowerCase().includes(value.toLowerCase())) 
      setUsersToShow(arrayForFilter);
    }

    const handleClick = (): void => {
      // TODO: Clear the search input
      setSearch("");
      filter("");
    };

    useEffect(() => {
        dispatch(
          getAuthorities()
        );
        dispatch(
            getAllUsers()
        );
        
    }, [])

    useEffect(() => {
      setUsersToShow(users);
    }, [users]);

    const handlePagination = (val: number) => {
      setPaginationNumber(val - 1)
    }
 
    return (
        <>
          <FormControl>
            <TextField
              fullWidth
              variant="outlined"
              placeholder='Cerca utente'
              value={search}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ display: showClearIcon }}
                    onClick={handleClick}
                  >
                    <ClearIcon style={{cursor: "pointer"}} />
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
          {users?.length > 0 ? (
            <>
              <UsersTable
                users={usersToShow}
                authorities={authorities}
                rowsPerPage={6}
              />
            </>
          ) : (
            <NoData
              title="Non c'è nessuno!"
              description="nessun utente registrato"
            />
          )}

          <CreationDialog editMode={false} userId={null} user={userCreation} setUser={setUserCreation} authorities={authorities} open={openCreate} setOpen={setOpenCreate} />

          <Fab style={{position: 'fixed', bottom: '30px', right: '30px'}} color="primary" aria-label="add" onClick={()=>setOpenCreate(true)}>
            <Add />
          </Fab>
        </>
      )
  
    
}

export default Users