import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import {
  selectLoggedIn,
  selectUser,
  logout
} from '../../store/reducers/AuthReducer'
import { showToast } from '../../store/reducers/ToastReducer'

import {
  ADD_RESERVATION_PATH,
  APPROVAL_BOOKING_PATH,
  GROUP_BOOKING_PATH,
  RESERVATIONS_PATH,
  SIGN_IN_PATH,
  DETAILS_TABLE_RESERVATION,
  USER_PATH,
  GROUP_PATH
} from '../routes/constants'

const RoleGuard: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const userInfo = useSelector(selectUser)
  const location = useLocation()
  const hasLoggedIn = useSelector(selectLoggedIn)
  const dispatch = useDispatch()
  const handleAuth = () => {
    dispatch(
      showToast({
        type: 'error',
        message: `You need authorization to enter`
      })
    )
    dispatch(logout())
  }

  const [guardedRoutes] = useState(
    new Map([
      [
        `/${RESERVATIONS_PATH}`, // all authenticated users are allowed to access this page
        [
          'ROLE_USER',
          'ROLE_ADMIN',
          'ROLE_GROUP_ADMIN',
          'ROLE_USER_SUPERVISOR',
          'ROLE_GROUP_SUPERVISOR'
        ]
      ],
      [
        `/${RESERVATIONS_PATH}/${ADD_RESERVATION_PATH}`, // all authenticated users are allowed to access this page
        [
          'ROLE_USER',
          'ROLE_ADMIN',
          'ROLE_GROUP_ADMIN',
          'ROLE_USER_SUPERVISOR',
          'ROLE_GROUP_SUPERVISOR'
        ]
      ],
      [
        `/${RESERVATIONS_PATH}/${APPROVAL_BOOKING_PATH}`, // only admin and supervisors are allowed to access the approval booking page
        ['ROLE_ADMIN', 'ROLE_USER_SUPERVISOR', 'ROLE_GROUP_SUPERVISOR']
      ],
      [
        `/${RESERVATIONS_PATH}/${DETAILS_TABLE_RESERVATION}`, // only admin and supervisors are allowed to access the approval booking page
        [
          'ROLE_ADMIN',
          'ROLE_USER_SUPERVISOR',
          'ROLE_GROUP_SUPERVISOR',
          'ROLE_GROUP_ADMIN'
        ]
      ],
      [
        `/${RESERVATIONS_PATH}/${GROUP_BOOKING_PATH}`, // only admin and group users are allowed to access the group booking page
        ['ROLE_ADMIN', 'ROLE_GROUP_ADMIN', 'ROLE_GROUP_SUPERVISOR']
      ],
      [
        `/${RESERVATIONS_PATH}/${USER_PATH}`, // only admin and group users are allowed to access the group booking page
        ['ROLE_ADMIN', 'ROLE_USER_SUPERVISOR']
      ],
      [
        `/${RESERVATIONS_PATH}/${GROUP_PATH}`, // only admin and group users are allowed to access the group booking page
        ['ROLE_ADMIN', 'ROLE_GROUP_ADMIN', 'ROLE_GROUP_SUPERVISOR']
      ],
    ])
  )

  return (
    <>
      {hasLoggedIn && !!guardedRoutes.get(location.pathname) ? (
        userInfo.authority_names.length === 0 ? (
          handleAuth()
        ) : userInfo.authority_names.some(an =>
            guardedRoutes.get(location.pathname).includes(an)
          ) ? (
          children
        ) : (
          <Navigate to={`/${RESERVATIONS_PATH}`} replace={true} />
        )
      ) : hasLoggedIn ? (
        children
      ) : (
        <Navigate to={`/${SIGN_IN_PATH}`} replace={true} />
      )}
    </>
  )
}

export default RoleGuard
