import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { AUTH_CONTROLLER } from 'constants/URLS'
import { AuthError, SignUpResponse, UserRegistration } from 'models/Auth.model'
import { isAxiosError } from 'utils/errors'
import axiosInstance from '../../app/axios'
import { hide, show } from '../reducers/LoaderReducer'
import { showToast } from '../reducers/ToastReducer'
import { IReservationsError } from 'models/Reservation.model'

export const doRegistration = createAsyncThunk<
  SignUpResponse,
  UserRegistration,
  {
    rejectValue: AxiosResponse<AuthError>
  }
>('auth/registration', async (user, { rejectWithValue, dispatch }) => {
  try {
    dispatch(show())

    const { data } = await axiosInstance.post(AUTH_CONTROLLER.signUp, {
      ...user
    })

    if (data?.success) {
      dispatch(
        showToast({
          type: 'success',
          message: 'Registrazione effettuata con successo!'
        })
      )
    } else {
      dispatch(
        showToast({
          type: 'error',
          message: 'Errore durante la registrazione'
        })
      )
    }

    dispatch(hide())

    return data
  } catch (err) {
    if (isAxiosError(err)) {
      const error = err as AxiosError<IReservationsError>
      dispatch(hide())

      dispatch(
        showToast({
          type: 'error',
          // message: err.response?.data?.message || 'Nome utente o password errati'
          message:
            error.response?.data?.details?.[0] ||
            error.response?.data?.message ||
            'Errore durante la registrazione'
        })
      )

      return rejectWithValue(error.response)
    } else {
      // generic & not related to network error
      console.error(err)
    }
  }
})
