//Creation Dialog component
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from '@material-ui/core'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUser, updateUser } from '../../../store/thunk/userController'
import { showToast } from '../../../store/reducers/ToastReducer';
import React from 'react';
import { ISimpleUser } from 'models/Auth.model';
import { createGroup, updateGroup } from 'store/thunk/groupController';

interface creationDialogProps {
    editMode: boolean;
    group: any;
    setGroup: any;
    users: any;
    open: boolean;
    setOpen: any;
}

const CreationDialog: React.FC<creationDialogProps> = ({
    editMode,
    group,
    setGroup,
    users,
    open,
    setOpen
}) => {


    const dispatch = useDispatch();


    const checkData = () => {
        if (group.name.length > 1 && group.description.length > 1 && group.organizer != undefined && group.members.length > 0) {
          setOpen(false);
          if(editMode){
            dispatch(
                updateGroup(
                    {
                        id: group.id,
                        name: group.name,
                        description: group.description,
                        organizer: group.organizer,
                        members: group.members,
                        enabled: group.enabled,
                    }
                )
            );
          } else {
            dispatch(
                createGroup(
                    {
                        name: group.name,
                        description: group.description,
                        organizer: group.organizer,
                        members: group.members,
                        enabled: group.enabled,
                    }
                )
            );
          }
        setGroup({
            name: '',
            description: '',
            organizer: {},
            members: [],
            enabled: true,
        })
  
        } else {
          dispatch(
            showToast({
              type: 'error',
              message: "Compila tutti i campi"
            })
          )
        }
      }

return (
        <>
        <Dialog
            open={open}
            onClose={()=> setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {
               editMode ? "Modifica gruppo" : "Crea un nuovo gruppo"
              }
            </DialogTitle>
            <DialogContent>
              <TextField 
                fullWidth
                id="name" 
                error={group.name.length > 0 && group.name.length < 2}
                label="Nome" 
                variant="outlined" 
                value={group.name}
                onChange={(e) => {
                    setGroup({...group, name: e.target.value})
                  }
                }
                style={{marginTop: "10px", marginBottom: "10px"}}
              />
              <TextField 
                fullWidth
                id="description" 
                label="Descrizione" 
                variant="outlined" 
                value={group.description}
                error={group.description.length > 0 && group.description.length < 2}
                onChange={(e) => {
                    setGroup({...group, description: e.target.value})
                  }
                }
                style={{marginTop: "10px", marginBottom: "10px"}}
              />
              <FormGroup>
                <FormControlLabel control={
                  <Checkbox 
                    value={group.enabled} 
                    onChange={(e) => {
                      setGroup({...group, enabled: e.target.checked})
                    }
                  }                  />
                  } label="Abilitato" />
              </FormGroup>
              <FormControl fullWidth>
              <InputLabel id="organizer">Organizzatore</InputLabel>
                <Select
                  labelId="organizer-select"
                  id="organizer-select"
                  value={group.organizer.id}
                  renderValue={() => <div>{
                        group.organizer.firstname ? <Chip style={{marginLeft: "5px", marginRight: "5px", backgroundColor: "#2196f3", color: "white"}} key={group.organizer.id} label={
                            group.organizer.firstname + " " + group.organizer.lastname
                        } /> : null
                  }</div>}
                  label="Organizzatore"
                >
                  {users && users.length > 0 ? users.filter(
                    user => {
                        for(let i = 0; i < user.authorities.length; i++){
                            if(user.authorities[i].name === "ROLE_ADMIN" || user.authorities[i].name === "ROLE_GROUP_ADMIN" || user.authorities[i].name === "ROLE_GROUP_SUPERVISOR"){
                                return true;
                            }
                        }
                        return false;
                    }
                  ).map(user => (
                    <MenuItem onClick={
                      () => {
                        setGroup({...group, organizer: {
                            id: user.id,
                            firstname: user.firstname,
                            lastname: user.lastname,
                            username: user.username
                      }})
                    }
                    } key={user.id} value={user.id}>
                      <Checkbox 
                        checked={group.organizer.id === user.id ? true : false} 
                      />
                      <ListItemText primary={user.firstname + " " + user.lastname} />
                    </MenuItem>
                  ))
                   : null } 
                </Select>
              </FormControl>
              <FormControl fullWidth>
              <InputLabel id="members">Membri</InputLabel>
                <Select
                  labelId="members-select"
                  id="members-select"
                  value={group.members == undefined ? [] : group.members}
                  renderValue={(data) => <div>{
                    group.members.map(member => {
                        return <Chip style={{marginLeft: "5px", marginRight: "5px", backgroundColor: "#2196f3", color: "white"}} key={member.id} label={
                            member.firstname + " " + member.lastname
                        } />
                    })
                  }</div>}
                  multiple
                  label="Membri"
                >
                  {users && users.length > 0 ? users.map(user => (
                    <MenuItem onClick={
                      () => {
                        setGroup({...group, members: group.members.find(member => member.id === user.id) ? group.members.filter(member => member.id !== user.id) : [...group.members, {
                            id: user.id,
                            firstname: user.firstname,
                            lastname: user.lastname,
                            username: user.username
                        }]})
                    }
                    } key={user.id} value={user.id}>
                      <Checkbox 
                        checked={group.members.find(member => member.id === user.id) ? true : false} 
                      />
                      <ListItemText primary={user.firstname + " " + user.lastname} />
                    </MenuItem>
                  ))
                   : null } 
                </Select>
              </FormControl>


            </DialogContent>
            <DialogActions>
              <Button style={{color: "#2196f3"}} onClick={()=>setOpen(false)}>Annulla</Button>
              <Button style={{color: "#2196f3"}} onClick={checkData} >
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
        </>
    )
}

export default CreationDialog;