//Creation Dialog component
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from '@material-ui/core'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authorityToName } from './utility';
import { createUser, updateUser } from '../../../store/thunk/userController'
import { showToast } from '../../../store/reducers/ToastReducer';
import React from 'react';

interface creationDialogProps {
    editMode: boolean;
    user: any;
    setUser: any;
    userId: number;
    authorities: any;
    open: boolean;
    setOpen: any;
}

const CreationDialog: React.FC<creationDialogProps> = ({
    editMode,
    user,
    setUser,
    userId,
    authorities,
    open,
    setOpen
}) => {


    const dispatch = useDispatch();

    let emailRegex = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";

    const checkData = () => {
        if (user.firstname.length > 1 && user.lastname.length > 1 && user.email.length > 1 && user.email.match(emailRegex) && user.username.length > 1 && user.authorities.length > 0) {
          setOpen(false);
          if(editMode){
            dispatch(
                updateUser(
                    {
                        id: userId,
                        body: {
                            username: user.username,
                            email: user.email,
                            firstname: user.firstname,
                            lastname: user.lastname,
                            enabled: user.enabled,
                            notificationsEnabled: true,
                            authoritiesId: user.authorities.map(authority => parseInt(authority)),
                            companyId: null
                        }
                    }
                )
            );
          } else {
            dispatch(
                createUser(
                    {
                        username: user.username,
                        email: user.email,
                        firstname: user.firstname,
                        lastname: user.lastname,
                        enabled: user.enabled,
                        password: "password",
                        notificationsEnabled: true,
                        authoritiesId: user.authorities.map(authority => parseInt(authority)),
                        companyId: null
                    }
                )
            );
          }

          setUser(
            {
              firstname: "",
              lastname: "",
              email: "",
              username: "",
              enabled: false,
              authorities: []
            }
          );
          
        } else {
          dispatch(
            showToast({
              type: 'error',
              message: "Compila tutti i campi"
            })
          )
        }
      }

    return (
        <>
        <Dialog
            open={open}
            onClose={()=> setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {
               editMode ? "Modifica utente" : "Crea un nuovo utente"
              }
            </DialogTitle>
            <DialogContent>
              <TextField 
                fullWidth
                id="firstname" 
                error={user.firstname.length > 0 && user.firstname.length < 2}
                label="Nome" 
                variant="outlined" 
                value={user.firstname}
                onChange={(e) => {
                    setUser({...user, firstname: e.target.value})
                  }
                }
                style={{marginTop: "10px", marginBottom: "10px"}}
              />
              <TextField 
                fullWidth
                id="lastname" 
                label="Cognome" 
                variant="outlined" 
                value={user.lastname}
                error={user.lastname.length > 0 && user.lastname.length < 2}
                onChange={(e) => {
                    setUser({...user, lastname: e.target.value})
                  }
                }
                style={{marginTop: "10px", marginBottom: "10px"}}
              />
              <TextField 
                fullWidth
                id="username" 
                label="Username" 
                variant="outlined" 
                value={user.username}
                error={user.username.length > 0 && user.username.length < 2}
                onChange={(e) => {
                  setUser({...user, username: e.target.value})
                }
              }
                style={{marginTop: "10px", marginBottom: "10px"}}
              />
              <TextField 
                fullWidth
                id="email" 
                label="Email" 
                variant="outlined" 
                value={user.email}
                error={user.email.length > 0 && !user.email.match(emailRegex)}
                onChange={(e) => {
                  setUser({...user, email: e.target.value})
                }
              }
                style={{marginTop: "10px", marginBottom: "10px"}}
              />
              <FormGroup>
                <FormControlLabel control={
                  <Checkbox 
                    checked={user.enabled}
                    value={user.enabled} 
                    onChange={(e) => {
                      setUser({...user, enabled: e.target.checked})
                    }
                  }                  />
                  } label="Abilitato" />
              </FormGroup>
              <FormControl fullWidth>
              <InputLabel id="authorities">Autorità</InputLabel>
                <Select
                  labelId="autorithies-select"
                  id="autorithies-select"
                  value={user.authorities}
                  renderValue={(data) => <div>{
                    Array.of(data).join(", ").split(",").map(authority => {
                      return <Chip style={{marginLeft: "5px", marginRight: "5px", backgroundColor: "#2196f3", color: "white"}} key={authority} label={
                        authorityToName(authorities.find(authorityInside => authorityInside.id === parseInt(authority)).name)
                      } />
                    })
                  }</div>}
                  multiple
                  label="Autorità"
                >
                  {authorities && authorities.length > 0 ? authorities.map(authority => (
                    <MenuItem onClick={
                      () => {
                        setUser({...user, authorities: user.authorities.indexOf(authority.id) > -1 ? user.authorities.filter(id => id !== authority.id) : [...user.authorities, authority.id]})
                    }
                    } key={authority.id} value={authority.id}>
                      <Checkbox 
                        checked={user.authorities.indexOf(authority.id) > -1} 
                      />
                      <ListItemText primary={authorityToName(authority.name)} />
                    </MenuItem>
                  ))
                   : null } 
                </Select>
              </FormControl>


            </DialogContent>
            <DialogActions>
              <Button style={{color: "#2196f3"}} onClick={()=>setOpen(false)}>Annulla</Button>
              <Button style={{color: "#2196f3"}} onClick={checkData} >
                Conferma
              </Button>
            </DialogActions>
          </Dialog>
        </>
    )
}

export default CreationDialog;