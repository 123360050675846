type environmentProps = {
  maximumAvailableSeats: number
  pageSize: number
}

export const ENVIRONMENT_VARIABLES: environmentProps = {
  maximumAvailableSeats:
    Number(process.env.REACT_APP_MAXIMUM_AVAILABLE_SEATS) || 4,
  pageSize: Number(process.env.REACT_APP_PAGE_SIZE) || 10
}
