import Typography from '@material-ui/core/Typography'
import {
  RECOVER_PASSWORD,
  RESERVATIONS_PATH,
  SIGN_UP_PATH
} from 'app/routes/constants'
import * as React from 'react'
import { useEffect, useState, VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { doLogin } from 'store/thunk/login'
import {
  doFirstLogin,
  selectFirstLogin
} from '../../../store/reducers/AuthReducer'
import { StyledTextField } from '../../components/common'
import {
  Anchor,
  Form,
  LoginButton,
  Register,
  ResetPasswordLink
} from './LoginPage.styled'
import { messages } from './messages'

const LoginPage: VFC = () => {
  const { t } = useTranslation()
  const [erEmail, setErEmail] = useState(false)
  const [erPassword, setErPassword] = useState(false)
  const [usernameOrEmail, setUsernameOrEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const firstLogin = useSelector(selectFirstLogin)
  const navigate = useNavigate()

  const from = RESERVATIONS_PATH

  useEffect(() => {
    if (firstLogin) {
      dispatch(doFirstLogin())
      navigate(from)
    }
  }, [dispatch, navigate, firstLogin, from])

  const goToRegistration = () => navigate(`/${SIGN_UP_PATH}`, { replace: true })

  const validate = (value): boolean => {
    let reg = new RegExp('^[0-9]*$')
    return !reg.test(value)
  }

  const login = () => {
    if (!disabled() && !erPassword && !erEmail && usernameOrEmail && password) {
      const user = {
        usernameOrEmail,
        password
      }
      dispatch(doLogin(user))
    }
  }

  const onKeyDown = (submit, isValid, event) => {
    if (isValid && event.key === 'Enter') {
      submit()
    }
  }

  const disabled = () => !usernameOrEmail || !password || erEmail || erPassword

  return (
    <Form noValidate onKeyDown={event => onKeyDown(login, !disabled(), event)}>
      <StyledTextField
        error={erEmail}
        helperText={erEmail && 'Email non valida'}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={t(...messages.form.email())}
        name="email"
        autoComplete="email"
        autoFocus
        value={usernameOrEmail}
        onChange={e => {
          setUsernameOrEmail(e.target.value)
          if (!validate(e.target.value)) {
            setErEmail(true)
            return
          }
          setErEmail(false)
        }}
      />
      <StyledTextField
        error={erPassword}
        helperText={erPassword && 'Password non valida'}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label={t(...messages.form.password())}
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={e => {
          setPassword(e.target.value)
          if (!validate(e.target.value)) {
            setErPassword(true)
            return
          }
          setErPassword(false)
        }}
      />
      <Typography align="right">
        <ResetPasswordLink href={`/${RECOVER_PASSWORD}`}>
          Hai dimenticato la password?
        </ResetPasswordLink>
      </Typography>
      <LoginButton onClick={login} buttonText="ENTRA" disabled={disabled()}>
        {t(...messages.form.btnConfirm())}
      </LoginButton>
      <Register>
        Non hai ancora un account?{' '}
        <Anchor onClick={goToRegistration}>REGISTRATI</Anchor>
      </Register>
    </Form>
  )
}

export default LoginPage
