import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        marginTop: '50px'
      },
      display: 'flex',
      justifyContent: 'center'
    }
  })
)

interface paginationProps {
  range: any
  setPage: any
  page: any
  slice: any
}

const PaginationTable: React.FC<paginationProps> = ({
  range,
  setPage,
  page,
  slice
}) => {

    useEffect(() => {
        if (slice.length < 1 && page !== 1) {
            setPage(page - 1);
        }
    }, [slice, page, setPage]);

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Pagination
        onChange={(event, val) => setPage(val)}
        size="medium"
        count={range.length}
        showFirstButton
        showLastButton
      />
    </div>
  )
}
export default PaginationTable
