import React from 'react'
import moment from 'moment'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Typography from '@material-ui/core/Typography'
import { capitalize } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getGroupReservationsbyDay } from '../../../../store/thunk/getReservationsDay'
import { useParams } from 'react-router'
import { ENVIRONMENT_VARIABLES } from '../../../../constants/variables'

import {
  selectIconState,
  toogleIcon
} from '../../../../store/reducers/UserIconReducer'


const useStyles = makeStyles((theme: Theme) => ({
  tableHeaderCell: {
    fontSize: '1.5rem',
    color: '#0b5559',
    textAlign: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      padding: '5px'
    }
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }
}))

interface cellheaderProps {
  iconLabel: string
  sort: Function
}

const UserTableHeader: React.FC<cellheaderProps> = ({
  iconLabel,
  sort
}) => {
  const icons = useSelector(selectIconState)
  const dispatch = useDispatch()

  const iconstate = {
    nome: null,
    cognome: null,
    username: null,
  }

  const chanceIcon = icon => {
    if (icons[icon] === null) iconstate[icon] = 'up'
    if (icons[icon] === 'up') iconstate[icon] = 'down'
    if (icons[icon] === 'down') iconstate[icon] = 'up'
    dispatch(toogleIcon(iconstate))
    sort(icon, icons[icon])
  }

  const classes = useStyles()

  return (
    <TableCell
      className={classes.tableHeaderCell}
      onClick={() => {
        chanceIcon(iconLabel)
      }}
    >
      <div className={classes.label}>
        <Typography variant="h6" color="initial">
          {capitalize(iconLabel)}
        </Typography>

        {icons[iconLabel] === null && <FormatLineSpacingIcon />}
        {icons[iconLabel] === 'up' && <ArrowUpwardIcon />}
        {icons[iconLabel] === 'down' && <ArrowDownwardIcon />}
      </div>
    </TableCell>
  )
}

export default UserTableHeader
