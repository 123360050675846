import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { showToast } from '../reducers/ToastReducer'
import axiosInstance from '../../app/axios'
import { IReservationsError } from '../../models/Reservation.model'
import { isAxiosError } from '../../utils/errors'
import { USER_CONTROLLER } from './../../constants/URLS'

type IchangePassword = {
  currentPassword: string
  newPassword: string
}
export const changePassword = createAsyncThunk<
  void,
  IchangePassword,
  {
    rejectValue: AxiosResponse<IReservationsError>
  }
>(
  'reservation/changePassword',
  async (requestBody: IchangePassword, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post(
        `${USER_CONTROLLER.changePassword}`,
        {
          ...requestBody
        }
      )
      dispatch(
        showToast({
          type: 'success',
          message: 'Password aggiornata con successo!'
        })
      )
      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError>
        dispatch(
          showToast({
            type: 'error',
            message:
              error.response?.data?.details?.[0] ||
              error.response?.data?.message ||
              "Errore durante l'aggiornamento della password"
          })
        )
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
