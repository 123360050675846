import React from 'react'
import { useDispatch } from 'react-redux'
import Container from '@material-ui/core/Container'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { MainCard, StyledTextField } from '../../components/common'
import { useFormik } from 'formik'
import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import { changePassword } from '../../../store/thunk/changePassword'

const useStyles = makeStyles((theme: Theme) => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
    marginTop: '10px'
  },
  buttonConfim: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderRadius: '14px',
    width: '100px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      filter: 'brightness(0.85)',
      boxShadow: 'none'
    },
    '&:disabled': {
      backgroundColor: '#75747449',
      color: '#7171710'
    }
  },
  buttonReset: {
    backgroundColor: '#efeef5',
    color: '#717171',
    textTransform: 'capitalize',
    borderRadius: '14px',
    width: '100px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#efeef5',
      filter: 'brightness(0.85)',
      boxShadow: 'none'
    }
  },

  logoOuter: {
    backgroundColor: '#dff3e8',
    height: '116px',
    width: '116px',

    borderRadius: '50%',
    display: 'flex'
  },
  logoInner: {
    display: 'flex',
    opacity: '1',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#138b3b',
    width: '76px',
    height: '76px',
    padding: '0',

    borderRadius: '50%',
    margin: 'auto'
  },
  logoIcon: {
    color: 'rgba(255, 255, 255, 0.92)'
  }
}))

const StyledContainer = styled(Container)`
  padding: 0 20px;
  max-width: 500px;
  min-width: 300px;

  @media (max-width: 500px) {
    padding: 5px;
  }
`

const StyledMainCard = styled(MainCard).attrs(props => ({
  fade: true,
  size: props
}))`
  position: relative;
  padding: 15px 31px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-style: none;
  height: 650px;

  .MuiFormHelperText-contained {
    position: absolute;
    top: 7px;
    right: 0;
  }
`
const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  gap: 20px;
`

const initialValues = {
  currentPassword: '',
  password: '',
  confirmPassword: ''
}

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Password richiesta'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$%!?@])(?=.{8,})/,
      `La password deve contenere almeno 8 caratteri, una lettera
      maiuscola, una minuscola, un numero e un simbolo speciale
      (! @ # $ % ^ & *)'`
    )
    .required('Password richiesta'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'La password deve corrispondere')
    .required('Conferma password richiesta')
})

const ResetPassword: React.VFC = () => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      await dispatch(
        changePassword({
          currentPassword: values.currentPassword,
          newPassword: values.password
        })
      )
      formik.resetForm()
    }
  })
  const classes = useStyles()
  return (
    <>
      <StyledContainer>
        <StyledMainCard style={{ borderRadius: '25px' }}>
          <div className={classes.containerForm}>
            <div className={classes.logoOuter}>
              <div className={classes.logoInner}>
                <VpnKeyIcon className={classes.logoIcon} fontSize="large" />
              </div>
            </div>
          </div>
          <Typography
            variant="h4"
            color="initial"
            align="center"
            style={{
              fontWeight: 'bold',
              color: '#717171'
            }}
          >
            Reset Password
          </Typography>
          <Typography
            variant="subtitle1"
            color="initial"
            align="center"
            style={{
              margin: '0',
              maxWidth: '300px'
            }}
          >
            Inserisci la tua password corrente e conferma la nuova password
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <StyledTextField
              size="small"
              id="currentPassword"
              label="PASSWORD CORRENTE"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currentPassword}
              error={
                formik.touched.currentPassword &&
                !!formik.errors.currentPassword
              }
              helperText={
                formik.touched.currentPassword && formik.errors.currentPassword
              }
              margin="normal"
              fullWidth
              {...formik.getFieldProps('currentPassword')}
            />

            <StyledTextField
              size="small"
              id="password"
              label="NUOVA PASSWORD"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              margin="normal"
              fullWidth
              {...formik.getFieldProps('password')}
            />

            <StyledTextField
              size="small"
              id="confirmPassword"
              label="CONFERMA PASSWORD "
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              error={
                formik.touched.confirmPassword &&
                !!formik.errors.currentPassword
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              margin="normal"
              fullWidth
              {...formik.getFieldProps('confirmPassword')}
            />
            <ContainerButton>
              <Button
                className={classes.buttonReset}
                variant="contained"
                onClick={formik.handleReset}
              >
                Anulla
              </Button>
              <Button
                className={classes.buttonConfim}
                color="primary"
                variant="contained"
                type="submit"
                disabled={!formik.isValid || !formik.dirty}
              >
                Conferma
              </Button>
            </ContainerButton>
          </form>
        </StyledMainCard>
      </StyledContainer>
    </>
  )
}

export default ResetPassword
