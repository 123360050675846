import { useQuery } from 'app/hooks/useQuery'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Copyright } from 'app/components'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowBackIos } from '@material-ui/icons'
import { StyledContainer } from '../LoginPage/LoginPage.styled'
import styled from 'styled-components'
import { MainCard, StyledTextField } from 'app/components/common'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import ShadowButton from 'app/components/ShadowButton'
import { useAppDispatch } from 'store/configureStore'
import { changePasswordFromRecovery } from 'store/thunk/ChangePasswordFromRecovery'
import { RECOVER_PASSWORD } from 'app/routes/constants'

const StyledMainCard = styled(MainCard).attrs(() => ({
  fade: true
}))`
  position: relative;
  padding: 15px 31px;

  .MuiFormHelperText-contained {
    position: absolute;
    top: 7px;
    right: 0;
  }
`
const BackIcon = styled(ArrowBackIos)`
  cursor: pointer;
  position: absolute;
  height: 45px;
  width: 45px;
  left: 12px;
  top: 12px;
  color: ${props => props.theme.palette.secondary.main};
`
const Title = styled.h3`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: normal;
  border-bottom: 6px solid ${props => props.theme.palette.secondary.main};
  text-align: center;
  display: block;
  margin: 0 auto 15px;
  transition: 0.2s;
  transition-timing-function: ease-in-out;

  :hover {
    transform: translateY(-5px);
  }
`
const SubmitButton = styled(ShadowButton).attrs(({ theme, disabled }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main,
  primary: true,
  size: 'large',
  disabled
}))`
  margin-bottom: 0;
  width: 100%;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  box-shadow: none;

  :hover {
    box-shadow: none;
  }
`

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$%!?@])(?=.{8,})/,
      `La password deve contenere almeno 8 caratteri, una lettera
      maiuscola, una minuscola, un numero e un simbolo speciale
      (! @ # $ % ^ & *)'`
    )
    .required('Password richiesta'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'La password deve corrispondere')
    .required('Conferma password richiesta')
})

const initialValues = {
  password: '',
  confirmPassword: ''
}

const ResetPasswordFromRecovery = () => {
  const query = useQuery()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [token, setToken] = useState('')

  const goToSignIn = React.useCallback(() => {
    navigate('/sign-in', { replace: true })
  }, [navigate])

  const goToRecoverPassword = React.useCallback(() => {
    navigate(`/${RECOVER_PASSWORD}`, { replace: true })
  }, [navigate])

  useEffect(() => {
    const tokenParam = query.get('token')
    if (tokenParam) setToken(tokenParam)
  }, [query])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (data, { resetForm, setSubmitting }) => {
      try {
        setSubmitting(true)
        const resultAction = await dispatch(
          changePasswordFromRecovery({ token, newPassword: data.password })
        )
        if (changePasswordFromRecovery.fulfilled.match(resultAction)) {
          goToSignIn()
        }
      } finally {
        setSubmitting(false)
        resetForm()
      }
    }
  })

  const onKeyDown = (submit, isValid, event) => {
    if (isValid && event.key === 'Enter') {
      submit()
    }
  }

  return (
    <StyledContainer>
      <StyledMainCard>
        <BackIcon onClick={goToRecoverPassword} />
        <Title>Crea una nuova password</Title>
        <Typography align="center" variant="body2" color="textSecondary">
          La nuova password deve essere diversa dalle precedenti
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          onKeyDown={event =>
            onKeyDown(formik.handleSubmit, formik.isValid, event)
          }
        >
          <StyledTextField
            size="small"
            id="password"
            label="NUOVA PASSWORD"
            type="password"
            autoComplete="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
            margin="normal"
            fullWidth
            {...formik.getFieldProps('password')}
          />

          <StyledTextField
            size="small"
            id="confirmPassword"
            label="CONFERMA PASSWORD "
            type="password"
            autoComplete="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            error={formik.touched.confirmPassword}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            margin="normal"
            fullWidth
            {...formik.getFieldProps('confirmPassword')}
          />
          <SubmitButton
            onClick={formik.handleSubmit}
            buttonText={formik.isSubmitting ? 'caricamento...' : 'continua'}
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
          />
        </form>
      </StyledMainCard>
      <Box mt={2}>
        <Copyright />
      </Box>
    </StyledContainer>
  )
}

export default ResetPasswordFromRecovery
